import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import buildingIcon from '../../assets/images/icons/building-icon.svg';
import piechartIcon from '../../assets/images/icons/piechart-icon.svg';
import searchIcon from '../../assets/images/icons/search-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';

import investorsExample from '../../assets/images/examples/paperos-investors.png';
import portalExample from '../../assets/images/examples/paperos-portal.png';
import reportsExample from '../../assets/images/examples/paperos-reports.png';
import projectsExample from '../../assets/images/examples/projects.png';
import docsExample from '../../assets/images/examples/file-cabinet.png';

import { Button, Footer, FloatingList, Sitemap, Topbar } from '../../components/common';
import { PaperOSFounders } from '../../components/PaperOSBranding';

import { generateBem } from '../../utils/generateBem';
import './Founders.scss';

const bem = generateBem('Founders');

const solutionsList = [
  {
    icon: buildingIcon,
    iconClassName: bem('floatingIcon'),
    image: reportsExample,
    title: 'Formation & Compliance',
    description:
      'One-stop shop for your business formation & compliance needs for any entity type including corporations, LLCs,  special purpose vehicles, and more.',
  },
  {
    icon: piechartIcon,
    iconClassName: bem('floatingIcon'),
    image: portalExample,
    title: 'Contracts & Cap Table',
    description:
      'Quickly generate, send, & sign legal documents that connect to your cap table and corporate ledger.',
  },
  {
    icon: searchIcon,
    image: projectsExample,
    iconClassName: bem('floatingIcon'),
    title: 'Fundraising & Diligence',
    description:
      'Streamlined fundraising ops with deal portals, data rooms, diligence checklists, contract automation, investor onboarding & SEC compliance.',
  },
  {
    icon: scalesIcon,
    iconClassName: bem('floatingIcon'),
    image: docsExample,
    title: 'Reporting & Governance',
    description:
      'Simple Portals for Investors & Board Members for information rights, reporting, & action reminders.',
  },
  {
    icon: stackIcon,
    image: investorsExample,
    iconClassName: bem(['floatingIcon']),
    imageClassName: bem(['sectionItemImage--shadow']),
    title: 'Exit & Liquidity Events',
    description:
      'Synchronized exit & liquidity strategy, planning, & administration services.',
  },
];

const pricingList = [
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Try it Free',
    colHeader: 'Pre-Funding Professionals',
    list: [
      'Pre-Funding Workflows',
      'Entity Formation & Compliance*',
      'Corporate Transparency (BOI) Reporting',
      'Operating & Equity Agreements',
      'Banking Setup via Mercury',
      'Financing Diligence Checklists',
      'Fundraising Portals',
      'Data Rooms',
      'Doc Send & Sign',
      'SAFE Agreements',
      'Team Onboarding',
      "NDA's",
    ],
    additionalInfoLabel: 'Additional Professional Features',
    additionalInfoLink: '/plans',
    prePrice: 'Starting at',
    price: '$499',
    postPrice: 'per year',
    footerList: [
      '*Excludes state fees',
      'Registered Agent ($99/yr)',
      'State Filing Agent ($99/yr)',
    ],
  },
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Request a Quote',
    colHeader: 'Portfolio Companies',
    list: [
      'Post-Funding Workflows',
      'Investor Onboarding',
      'Investor Reporting',
      'SEC Compliance*',
      'Subsidiary Management',
      'Preferred Stock Automation',
      'Pre-Exit Workflows',
      'M&A Diligence Checklists',
      'Custom Contract Automation',
      'Multi-Entity Discounts',
    ],
    additionalInfoLabel: 'Additional Portfolio Features',
    additionalInfoLink: '/plans',
    prePrice: 'Starting at',
    // prePrice: 'Pricing varies by Fund Type',
    price: '$2,000',
    postPrice: 'per year',
    footerList: ['*Variable fees apply.'],
  },
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Contact Sales',
    colHeader: 'Add-on Services',
    list: [
      'Full-Service Formations',
      'Full-Service Financings',
      'Cap Table Management',
      'Corporate Administration',
      'Legal Diligence Audits',
      'End-of-Life Services',
      'Custom Workflow Implementation',
      'Custom Integrations',
      'Counsel & Drafting*',
      'Valuation Services*',
      'Taxes & Financials*',
      'Exit & Estate Planning*',
    ],
    prePrice: 'Flexible Service Plans',
    footerList: ['*via the PaperOS Partner Network'],
  },
];

const Founders = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroColumn')}>
          <div className={bem('heroTextWrapper')}>
            <h1 className={bem('heroHeading')}>
              Integrated
              <br />
              Founder Suite
            </h1>
            <h3 className={bem('heroSubtext')}>
              One platform for founder operations with end-to-end legal, compliance, &
              admin services.
            </h3>
          </div>
          <Button
            className={bem('heroButton')}
            isFlat
            href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={investorsExample}
            alt="Paperos Investors Page Example"
          />
          <div className={bem('floatingList')}>
            <FloatingList items={solutionsList} zIndex="80" />
          </div>
        </div>
      </div>
    </section>
    <div className={bem('section')}>
      {solutionsList.map(
        (
          { description, image, imageClassName, title, longTitle, icon },
          solutionIndex,
        ) => {
          const isEven = (solutionIndex + 1) % 2;
          return (
            !!description && (
              <section
                key={`solution-${title}`}
                className={bem(['sectionRow', isEven ? 'sectionRow--even' : null])}
              >
                <div
                  className={bem([
                    'sectionItem',
                    isEven ? 'sectionItem--rightPadding' : 'sectionItem--leftPadding',
                  ])}
                >
                  <div className={bem('sectionItemRow')}>
                    <img
                      className={bem('sectionItemIcon')}
                      src={icon}
                      alt={`${title} icon`}
                    />
                    <h2>{longTitle || title}</h2>
                  </div>
                  <div className={bem('sectionItemRow')}>
                    <p>{description}</p>
                  </div>
                </div>
                <div className={bem(['sectionItem', 'sectionItem--image'])}>
                  <img
                    className={`${bem('sectionItemImage')} ${imageClassName || ''}`}
                    src={image}
                    alt={`${title} Example UI`}
                  />
                </div>
              </section>
            )
          );
        },
      )}
    </div>
    <section className="container container--light">
      <div className={`${bem(['section', 'section--pricing'])}`}>
        <div className={bem('getStarted')}>
          <p>Get started with</p>
          {PaperOSFounders()}
        </div>
        {pricingList.map(
          (
            {
              additionalInfoLabel,
              additionalInfoLink,
              buttonHref,
              buttonText,
              colHeader,
              isSearchAdded,
              list,
              prePrice,
              price,
              postPrice,
              footerList = [],
            },
            pricingCardIndex,
          ) => (
            <div
              key={`pricing-list-item-${colHeader}`}
              className={bem([
                'pricingCard',
                (pricingCardIndex + 1) % 2 ? null : 'pricingCard--even',
              ])}
            >
              <div className={bem('pricingCardList')}>
                <h4>{colHeader}</h4>
                <ul>
                  {list.map(item => (
                    <li key={`price-item-${item}`}>{item}</li>
                  ))}
                  {!!additionalInfoLink && (
                    <Button buttonType="link" size="sm" to={additionalInfoLink}>
                      {additionalInfoLabel}
                      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                    </Button>
                  )}
                </ul>
              </div>
              <div className={bem('pricingCardBottom')}>
                <div className={bem('pricingCardPrePrice')}>{prePrice}</div>
                <div className={bem('pricingCardPrice')}>
                  {!!price && <h2>{price}</h2>}
                  <p className={bem('pricingCardPostPrice')}>{postPrice}</p>
                </div>
                <Button
                  className={bem('pricingCardAction')}
                  href={`${buttonHref}${isSearchAdded ? search : ''}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {buttonText}
                </Button>
                <div className={bem('pricingCardFooter')}>
                  {footerList.map(footer => (
                    <h4 key={`price-footer-${footer}`}>{footer}</h4>
                  ))}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </section>
    <Sitemap />
    <Footer />
  </>
);

export default Founders;
