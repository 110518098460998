import React from 'react';
import classnames from 'classnames';
// import { useLocation } from 'react-router-dom';

import { Button } from '..';

import savviIcon from '../../../assets/images/paperOS_capital_at_scale_white_lg.png';

import './Sitemap.scss';

const bem = elementName => `Sitemap${elementName ? '__' + elementName : ''}`;

const Sitemap = ({ isLight }) => {
  // const { search = '' } = useLocation();
  const activateHubspotChatter = e => {
    e.preventDefault();
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.open();
        },
      ];
    }
  };
  return (
    <section className={classnames(bem('Section'), { isLight })}>
      <div className={bem('SectionRow')}>
        <div className={bem('pairedColumn')}>
          <div className={bem('introductionText')}>
            <div className={bem('introductionText--large')}>Still have questions?</div>
            <div className={bem('introductionText--normal')}>
              We'd be happy to help. Feel free to{' '}
              <Button size="sm" buttonType="link" onClick={activateHubspotChatter}>
                contact us via chat
              </Button>{' '}
              during business hours or schedule a call.
            </div>
          </div>
        </div>
        <div className={`${bem('pairedColumn')}`}>
          <div className={`${bem('joinUsWrapper')}`}>
            <img className={bem('joinUsImage')} src={savviIcon} alt="Savvi Icon" />
            {/* <h4 className={bem('joinUsSubheader')}>docs at scale.</h4> */}
            {/* <h2 className={bem('joinUsHeader')}>
              The Legal Hub
              <br />
              for the Startup Ecosystem
            </h2> */}
            <Button isFlat href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm">
              Talk to Sales
            </Button>
          </div>
        </div>
        {/* <div className={bem('pairedColumn')}>
          <div className={bem('Columns')}>
            <div className={`${bem('Column')}`}>
              <h4>Resources</h4>
              <a
                href="https://learn.savvi.legal/workflows"
                target="_blank"
                rel="noopener noreferrer"
              >
                Workflows
              </a>
              <a
                href="https://learn.savvi.legal/services"
                target="_blank"
                rel="noopener noreferrer"
              >
                Services
              </a>
              <a
                href="https://learn.savvi.legal/hardware-addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                Templates
              </a>
              <a
                href="https://learn.savvi.legal/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal FAQ's
              </a>
              <a
                href="https://learn.savvi.legal/user-guide"
                target="_blank"
                rel="noopener noreferrer"
              >
                User Guide
              </a>
            </div>
            <div className={`${bem('Column')}`}>
              <h4>Product</h4>
              <Link
                to={{
                  pathname: '/home',
                  search,
                }}
              >
                Home
              </Link>
              <Link to={{ pathname: '/plans', search }}>Platform Pricing</Link>
              <a
                href="https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className={bem('Footer')}>
            <div className={bem('socialMediaWrapper')}>
              <a
                className={bem('socialMediaLink')}
                href="https://twitter.com/savvilegal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'twitter-square']} />
              </a>
              <a
                className={bem('socialMediaLink')}
                href="https://www.linkedin.com/company/savvi-technologies-inc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Sitemap;
